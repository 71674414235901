/*eslint-disable*/
import React from "react";

// reactstrap components
import {Row, Container} from "reactstrap";

function Footer() {
    return (
        <footer className="footer footer-black footer-white">
            <Container>
                <Row>
                    <nav className="footer-nav">
                        <ul>
                            <li>
                                <a
                                    href="https://www.styx.studio"
                                    target="_blank"
                                >
                                    Styx Studio
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="credits ml-auto">
                        <span className="copyright">
                            Copyright © {new Date().getFullYear()} Amal George Thomas, made with{" "}
                            <i className="fa fa-heart heart"/> in India
                        </span>
                    </div>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
