import React from "react";

// reactstrap components
import {
    UncontrolledCollapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Button,
    Row,
    Col, Card, Form, InputGroup, InputGroupAddon, InputGroupText, Input, Modal
} from "reactstrap";
import SectionJavaScript from "./SectionJavaScript";

function SocialLinks() {
    const [modal, setModal] = React.useState(false);
    const toggleModal = () => {
        setModal(!modal);
    };
    return (
        <>
            <div className="section">
                <Container>
                    <Row>
                        <Col className="mx-auto" lg="4" md="6">
                            <div className="social-line text-center">
                                <Button
                                    className="btn-neutral btn-just-icon mt-0 ml-1"
                                    color="facebook"
                                    href="https://facebook.com/amalgta"
                                >
                                    <i className="fa fa-facebook-square"/>
                                </Button>
                                <Button
                                    className="btn-neutral btn-just-icon mt-0 ml-1"
                                    color="twitter"
                                    href="https://twitter.com/amalgta"
                                >
                                    <i className="fa fa-twitter"/>
                                </Button>
                                <Button
                                    className="btn-neutral btn-just-icon mt-0 ml-1"
                                    color="medium"
                                    href="https://medium.com/@amalgta"
                                >
                                    <i className="fa fa-medium"/>
                                </Button>
                                <Button
                                    className="btn-neutral btn-just-icon mt-0 ml-1"
                                    color="github"
                                    href="https://github.com/amalgta"
                                >
                                    <i className="fa fa-github"/>
                                </Button>
                                <Button
                                    className="btn-neutral btn-just-icon mt-0 ml-1"
                                    color="instagram"
                                    href="https://instagram.com/amalgta"
                                >
                                    <i className="fa fa-instagram"/>
                                </Button>
                                <Button
                                    className="btn-neutral btn-just-icon mt-0 ml-1"
                                    color="pgp"
                                    onClick={toggleModal}
                                >
                                    <i className="fa fa-key"/>
                                </Button>
                            </div>
                            <Modal isOpen={modal} toggle={toggleModal}>
                                <div className="modal-header">
                                    <button
                                        aria-label="Close"
                                        className="close"
                                        type="button"
                                        onClick={toggleModal}
                                    >
                                        <span aria-hidden={true}>×</span>
                                    </button>
                                    <h5
                                        className="modal-title text-center"
                                        id="exampleModalLabel"
                                    >
                                        PGP Public Key
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <code>
                                        -----BEGIN PGP PUBLIC KEY BLOCK-----
                                        Version: Keybase OpenPGP v1.0.0
                                        Comment: https://keybase.io/crypto

                                        xsBNBF5hKUUBCADSxjN/0ykI5lq/parFgDI8kNqXX6crv/AX7pI8B2Z9PXJETYk8
                                        hYZn/y0bK8hyF3RukdaKKhpCiVx0erFqwXi2laVSiwmeHdfDV4SbcAJBpnvY7lER
                                        s9p7BI2TeWPqulrtnSk0i4ZK2SXcPfKAK97H7PzsDWIAnV1a0fNLndBGfBhq4QpP
                                        T5r55bgVMUva3YcWF+z2OZhO5upn3eSMNXtb1erUMoDFPLq7ZgilOf7tphWmfBdi
                                        bCmqY5b/twxwvpVZJC1sUN4nWWmUAuy40eeL8uhB/C1dp8pCObsom6kHLOMs2kQT
                                        7rYVnWQOLgcp0qWf95w1q/HdHhGBidNZxsq9ABEBAAHNJkFtYWwgR2VvcmdlIFRo
                                        b21hcyA8YW1hbGd0YUBnbWFpbC5jb20+wsBtBBMBCgAXBQJeYSlFAhsvAwsJBwMV
                                        CggCHgECF4AACgkQRrnqoP5lH8F15Af/QJovrAakBzYsK3YHat5RWk/bNkWXe/1B
                                        kIyD6QItcvIFNaRGTqVnGgiP+BC/uI6vmZr5OTIv9tiP09YU3rxQgi2OD5L6dqdi
                                        VyMNCHw1QfczneQ4CFL0yN7b8lOipM9HNpJkFqjKAonDVqUTx9sgfAEZVsyYZNTy
                                        jP6CPSq2W4ch/+LNSALi+YM+Hv6GdP2AqUgbSJ495Zq6r4yYICOCXkgIJZSM8bWL
                                        MZlhunjF1RSLvEAfNSQ8jyCIR7pTDRi7Wm3HxCmtPcfxjs56cd4nGvDvwGsfo3wr
                                        JKA6u3L+1C0bA7VzLjwAKzqSKe+yX01ek9/242lTOIyEwnNoydNN7c7ATQReYSlF
                                        AQgAmLqSvgYY49Tv4qKmv0fI2WPG58OiA/z4VBkVu6ZI8OSlgZI3L/E8mM/Eqfhn
                                        n1zieuLLkC1Mi4i+XOX2iHLYOJbiM1SQtoTOrn+gQnDb0BYS8v6uNGNLrXJztpFv
                                        vpVq7i7mdt5eq8Yo5KinWTU6V8xJFXqM53act1lpWmm3WEcgy8ENUV8hFFkCgO9c
                                        xQpjXZfL47IJTAlAsb/1JqVRWLVqrOol9jr+qQpZnGi1rgQ4V/4k0E3D+py+1cGs
                                        W7PQOZ21wT9xrXAwbwRki+PpBi58mgTiL3pxTgcyHFDtUFJlGrDhivIV7hgrKvSE
                                        W+p+sEMQjUMdKZAX2cVqy0nDRwARAQABwsGEBBgBCgAPBQJeYSlFBQkPCZwAAhsu
                                        ASkJEEa56qD+ZR/BwF0gBBkBCgAGBQJeYSlFAAoJECp08UJvdgcvJIgIAIVLWuUH
                                        LfLDQNfK38NcZyo6sJXDODJtmhpYj5jKe4gew3RLa0Q8PyWxgiwzgzOAjXoiJ7lf
                                        +0pT9bW9nJBLC07iDoHU00Qune/dCMqkPjeLzu0r1PzU69N224IzDL/QFITcmded
                                        cXUOFWu/59hezcqNywimLpOzWNwBBKMB8X8/3LR3UEnEcSIfLqNERxDmmFfmNhRn
                                        xDJAUcgXAYx3HtBtif3ZnbLXcuxb28cumDtqAPVuKCZkujQ29vKEMvmNjwbwjr8L
                                        T/zMFHQUhfLgr3pQKWZiPgZXz95Qa+NUAXdXlSRl3o+rB9xjXje1P/WQXi7hI4O5
                                        sdQEm5syqlvfEv7X2QgAiiFmmYqWfS9JYKxCinV/HXdQNBLLfroDxcZPRyFO8PqU
                                        /sq318QFq/nG3TwjgXvEyZEEkAA9JOzLOvD/8ofG9R926T94qjo1X0Hf/1AyQDqv
                                        TfqGdxM0VjRHEgJ/GscbU/kBDsocGeruOhLQ30Jm22/ott+j+oAoMn1GiLcV6KKZ
                                        PGSi3ZiYFqLa1SZZASQ6//7yuRGB0kd/AriJ2PQi6/88dNr56EKHKIsXy/O5gQK7
                                        5eZMvxuo7CMYshmK8aWT/XSfozc/gveF0bBtnqGE/cG6zlVhyZS2dBO3Vb/huS/O
                                        LGKjUbByxBf90o2DdnbBj/HcQ0bLROXwqPyC16xxXM7ATQReYSlFAQgApdNVt3eP
                                        b4OZbNdXifD2MR4zNOk/fDyBrhW+BdNZbH5dK6qcYU9hDq0kd7wWy5CogURYLRho
                                        /yz62Nkv5ghJkH+z30ncjMSHLsq5RtFlButl5cXddKkGTMZ1H9lrYO4KBnUxSi9q
                                        9jQhnAEqfGM8bRjN6CTQq2V362DGMwyDRCja6BhBoUCfrWY1rdz020rxgZUfdCs1
                                        Bg9UU1JVrjtsVlpnKr9mMSklGWMmLBjphauZxZ+EpQDDiHIAaTw4l4t1q9qcP+O6
                                        U9V8PeDvHyuJIQpPPBHJR+e6PThXzcKvwcd/vvwmP5iOxfmQRSvRaYxxdE7MPzZV
                                        hxchAsVy1S0VSQARAQABwsGEBBgBCgAPBQJeYSlFBQkPCZwAAhsuASkJEEa56qD+
                                        ZR/BwF0gBBkBCgAGBQJeYSlFAAoJEHMAtewE1oF9FwEH/0eg0d6auairJFIej4hO
                                        f4Ic4crMOOSRiIZEEZU349YbTBdVXVXnxw8eYRt7AgkYVvBoweIY1TDevedvQO6r
                                        w2UThCSZhc9JaxUI3iYLSXmTVEo7ow7HO9PvnEw/lTSu8j8RgPEG4x7tlFTcdiPn
                                        O/gqX9XqUSwIvgobaOjeWWPvw9U02gmXXcMdf+J3afNTn9bkzNZuNOEOElBlkhXM
                                        DxDqsnNLPSzo66LipHrKmvESsukpTxMM5+NpRWBZuXuXhaiL99zlOsKUo/HLco5Z
                                        BAojl8lxCAqTpNRumdJU/FhgGy7+7RlPhr/HujwiiG7/++CvJZizM0Wq/hhoeNCn
                                        CZTffAf/cLSY1kJb4UnMixQ43SUVaNIoUF8FD1TlbEILYmgu3jdfBY1lahjWZCAT
                                        Bv/EVt5SWv3g8hzWLFVyV4PUTo6SJzhL2kyJgvxyuClFSdFXWRBkACa8Hp05/cWg
                                        AH2iCHL1O6Uqi3zJmOsTrtCFJ33j7/OWTgaXH8iPyque1Upd/HhElGp8Y0EZ99xa
                                        YNW+p5CkKC9BO88C3bzuOaj5iCcIqfvEsygQc9RRbesGMIpHBtMdTZUXTAe/ERMP
                                        FbKA64HP24f14x3rng0W0LoT4o963pKjVgOWTzDD3ImkSZmpr5w3D3xABSnG7J+f
                                        kKgdiW+jgC/NrZoRqMVcLkf9YxDBYw==
                                        =IAqc
                                        -----END PGP PUBLIC KEY
                                    </code>
                                </div>
                            </Modal>
                            <div className="col text-center">
                                <Button
                                    className="btn-round"
                                    outline
                                    color="neutral"
                                    href="/console"
                                    size="lg"
                                    target="_blank"
                                >
                                    CONSOLE
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default SocialLinks;
