/*eslint-disable*/
import React from "react";

// reactstrap components
import {Button, Container} from "reactstrap";

// core components

function IndexHeader() {
    return (
        <>
            <div
                className="page-header section-dark"
                style={{
                    backgroundImage:
                        "url(" + require("../../assets/img/mb-bg-fb-24.webp") + ")"
                }}
            >
                <div className="filter"/>
                <div className="content-center">
                    <Container>
                        <div className="title-brand">
                            <h1 className="presentation-title">Amal Thomas</h1>
                            <h1 className="presentation-subtitle"></h1>
                        </div>
                    </Container>
                </div>
                <div
                    className="moving-clouds"
                    style={{
                        backgroundImage: "url(" + require("../../assets/img/clouds.webp") + ")"
                    }}
                />
            </div>
        </>
    );
}

export default IndexHeader;
