import React from "react";

// reactstrap components

// core components
import IndexNavbar from "../components/Navbars/IndexNavbar";
import IndexHeader from "../components/Headers/IndexHeader.js";
import Footer from "../components/Footers/Footer.js";

// index sections
import SectionButtons from "../views/index-sections/SectionButtons.js";
import SocialLinks from "../views/index-sections/SocialLinks.js";
import SectionNavbars from "../views/index-sections/SectionNavbars.js";
import SectionNavigation from "../views/index-sections/SectionNavigation.js";
import SectionProgress from "../views/index-sections/SectionProgress.js";
import SectionNotifications from "../views/index-sections/SectionNotifications.js";
import SectionTypography from "../views/index-sections/SectionTypography.js";
import SectionJavaScript from "../views/index-sections/SectionJavaScript.js";
import SectionCarousel from "../views/index-sections/SectionCarousel.js";
import SectionNucleoIcons from "../views/index-sections/SectionNucleoIcons.js";
import SectionDark from "../views/index-sections/SectionDark.js";
import SectionLogin from "../views/index-sections/SectionLogin.js";
import SectionExamples from "../views/index-sections/SectionExamples.js";
import SectionDownload from "../views/index-sections/SectionDownload.js";
import ProfilePage from "./examples/ProfilePage";
import ProfilePageHeader from "../components/Headers/ProfilePageHeader";
import {
    Button,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";

function Index() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("index");
        return function cleanup() {
            document.body.classList.remove("index");
        };
    });
    return (
        <>
            <IndexNavbar/>
            <IndexHeader/>
            <div className="main">
                <SocialLinks/>
                <Footer/>
            </div>
        </>
    );
}

export default Index;
