import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
    Button,
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container
} from "reactstrap";

function IndexNavbar() {
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [navbarCollapse, setNavbarCollapse] = React.useState(false);

    const toggleNavbarCollapse = () => {
        setNavbarCollapse(!navbarCollapse);
        document.documentElement.classList.toggle("nav-open");
    };

    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 299 ||
                document.body.scrollTop > 299
            ) {
                setNavbarColor("");
            } else if (
                document.documentElement.scrollTop < 300 ||
                document.body.scrollTop < 300
            ) {
                setNavbarColor("navbar-transparent");
            }
        };

        window.addEventListener("scroll", updateNavbarColor);

        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });
    return (
        <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
            <Container>
                <div className="navbar-translate">
                    <NavbarBrand
                        data-placement="bottom"
                        href="/"
                        target="_blank"
                    >
                        Amal Thomas
                    </NavbarBrand>
                    <button
                        aria-expanded={navbarCollapse}
                        className={classnames("navbar-toggler navbar-toggler", {
                            toggled: navbarCollapse
                        })}
                        onClick={toggleNavbarCollapse}
                    >
                        <span className="navbar-toggler-bar bar1"/>
                        <span className="navbar-toggler-bar bar2"/>
                        <span className="navbar-toggler-bar bar3"/>
                    </button>
                </div>
                <Collapse
                    className="justify-content-end"
                    navbar
                    isOpen={navbarCollapse}
                >
                    <Nav navbar>
                        <NavItem>
                            <NavLink
                                data-placement="bottom"
                                href="https://www.github.com/amalgta"
                                target="_blank"
                                title="Star on GitHub"
                            >
                                <i className="fab fa-github"/>
                                <p className="d-lg-none">GitHub</p>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                data-placement="bottom"
                                href="https://www.slideshare.net/amalgta/"
                                target="_blank"
                                title="View SlideShare Profile"
                            >
                                <i className="fab fa-slideshare"/>
                                <p className="d-lg-none">SlideShare</p>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                data-placement="bottom"
                                href="https://dev.to/amalgta"
                                target="_blank"
                                title="View DEV Profile"
                            >
                                <i className="fab fa-dev"/>
                                <p className="d-lg-none">DEV</p>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                data-placement="bottom"
                                href="https://twitter.com/amalgta"
                                target="_blank"
                                title="Follow me on Twitter"
                            >
                                <i className="fab fa-twitter"/>
                                <p className="d-lg-none">Twitter</p>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                data-placement="bottom"
                                href="https://www.facebook.com/amalgta"
                                target="_blank"
                                title="Connect on Facebook"
                            >
                                <i className="fab fa-facebook-square"/>
                                <p className="d-lg-none">Facebook</p>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                data-placement="bottom"
                                href="https://www.instagram.com/amalgta"
                                target="_blank"
                                title="Follow me on Instagram"
                            >
                                <i className="fab fa-instagram"/>
                                <p className="d-lg-none">Instagram</p>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                data-placement="bottom"
                                href="https://medium.com/@amalgta"
                                target="_blank"
                                title="Read my writes"
                            >
                                <i className="fab fa-medium"/>
                                <p className="d-lg-none">Medium</p>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="https://drive.google.com/file/d/1varr_WGVlLAL8tgNhUWkwmQpFHvWdZ0x/view?usp=sharing"
                                target="_blank"
                            >
                                <i className="nc-icon nc-book-bookmark"/> Resume
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default IndexNavbar;
